<template>
  <div class="bg-main d-flex flex-column h-100">
    <HeaderAuth />
    <div class="d-flex justify-center">
      <div class="auth-card text-center">
        <h2 class="auth-card__title mb-8">
          {{updatePasswordSuccess ? 'Пароль успешно изменен!' :  'Введите новый пароль'}}
        </h2>
        <v-btn v-if="updatePasswordSuccess" class="w-100" x-large color="primary"
               @click="$router.push({name: 'login'})">
          Войти
        </v-btn>
        <form v-else @submit.prevent="submit">
          <v-text-field label="Пароль"
                        v-model="password"
                        @input="onInput('password')"
                        @blur="$v.password.$touch()"
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPass ? 'text' : 'password'"
                        @click:append="showPass = !showPass"
                        :error-messages="passwordErrors"
                        outlined />

          <div v-for="(err, ind) in errors" :key="ind" class="error--text">
            <template v-if="Array.isArray(err[1]) && err[0] !== 'password'">
              <div v-for="(e, i) in err[1]" :key="i">
                <span v-if="err[0] === 'token'">Токен: {{e}}</span>
              </div>
            </template>
          </div>

          <v-btn type="submit" class="btn-disabled-primary mt-8 w-100" x-large color="primary"
                 :loading="loading" :outlined="loading || $v.$invalid" :disabled="loading || $v.$invalid">
            Продолжить
          </v-btn>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import { MESSAGES } from '@/common/constants/errorMessages';
import { PASSWORD } from '@/common/constants/validations';
import { startSpacesValidation } from '@/common/validationHelpers';
import HeaderAuth from '@/components/headers/HeaderAuth';

export default {
  name: 'UpdatePassword',
  metaInfo: {
    title: 'Введите новый пароль',
  },
  components: {
    HeaderAuth,
  },
  validations: {
    password: {
      required,
      startSpacesValidation,
      minLength: minLength(PASSWORD.MIN_LENGTH),
      maxLength: maxLength(PASSWORD.MAX_LENGTH),
    },
  },
  data: () => ({
    updatePasswordSuccess: false,
    showPass: false,
    password: '',
  }),
  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      errors: 'auth/errors',
    }),
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.startSpacesValidation) errors.push(MESSAGES.START_SPACES);
      if (!this.$v.password.maxLength) errors.push(MESSAGES.MAX_LENGTH(PASSWORD.MAX_LENGTH));
      if (!this.$v.password.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.password.minLength) errors.push(MESSAGES.MIN_LENGTH(PASSWORD.MIN_LENGTH));

      return this.errors.find((err) => err[0] === 'password')?.[1] ?? errors;
    },
  },
  methods: {
    ...mapActions({
      updatePassword: 'auth/updatePassword',
      clearError: 'auth/clearError',
    }),
    onInput(field) {
      this.$v[field].$touch();
      if (this.errors.length) this.clearError();
    },
    submit() {
      if (this.loading) return;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updatePassword({ password: this.password, token: this.$route.query.token }).then(() => {
          this.updatePasswordSuccess = true;
        });
      }
    },
  },
  beforeDestroy() {
    this.clearError();
  },
};
</script>
